
import { defineComponent } from 'vue';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainning-allowance-summary',
  components: {
    Datatable,
  },

  data() {
    return {
      pdfHeader: [] as any,
      institutes: [] as any,
      tranches: [] as any,
      associations: [] as any,
      courses: [] as any,
      batches: [] as any,

      TpartnerData: '',
      TranceData: '',
      Tinstitute: '',
      TbatchData: '',
      TcourseData: '',
      TpartnerLabel: '',
      TranceLabel: '',
      TinstituteLabel: '',
      TbatchLabel: '',
      TcourseLabel: '',
      entityInfos: [],
      selectedEntity: [],
      selectedTrance: [],
      selectedCourse: [],
      tableData: [] as any,
      tableHeader: [
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
          selected: true,
        },
        {
          name: 'Name',
          key: 'name',
          sortable: true,
          width: '5px',
          selected: true,
        },
        {
          name: 'Registration No',
          key: 'reg_no',
          sortable: true,
          selected: true,
        },
        {
          name: "Father's Name",
          key: 'fName',
          sortable: true,
        },
        {
          name: "Mother's Name",
          key: 'mName',
          sortable: true,
        },
        {
          name: 'Institute Name',
          key: 'institute_name',
          sortable: true,
          selected: true,
        },
        {
          name: 'Course Name',
          key: 'course_name',
          sortable: true,
          selected: true,
        },
        {
          name: 'Batch Number',
          key: 'batch_number',
          sortable: true,
        },

        {
          name: 'Total Class',
          key: 'total_class',
          sortable: true,
        },
        {
          name: 'Total Class Completed',
          key: 'total_class_completed',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Total Trainee Attendance',
          selected: true,
          key: 'trainee_attendance',
          sortable: true,
        },
        {
          name: 'Attendance Percentage (%)',
          key: 'percentage',
          sortable: true,
        },
        {
          name: 'Drop Out',
          key: 'dropOut',
          sortable: true,
        },
        {
          name: 'Bank Account',
          key: 'bank_account',
          sortable: true,
        },
        {
          name: 'Account Type',
          key: 'account_type',
          sortable: true,
        },
        {
          name: 'Bank Name',
          key: 'bank_name',
          sortable: true,
        },
        {
          name: 'Branch Name',
          key: 'branche_name',
          sortable: true,
        },
      ] as any,

      st: [] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: true,
    };
  },
  async created() {
    await this.associationList();
    await this.getTranche();
  },
  methods: {
    async exportTrainee() {
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('institute', this.Tinstitute);
      formData.set('course', this.TcourseData);
      formData.set('batch', this.TbatchData);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/traineerunningbatchreportExcel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'TraineeRunningBatch.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async downloadPdf() {
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('institute', this.Tinstitute);
      formData.set('course', this.TcourseData);
      formData.set('batch', this.TbatchData);

      // console.log(JSON.stringify(this.st));

      await ApiService.post('report/traineerunningbatchreportPdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
     async printPdf() {
   let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('institute', this.Tinstitute);
      formData.set('course', this.TcourseData);
      formData.set('batch', this.TbatchData);

      let data = `${this.VUE_APP_API_URL}/api/report/traineerunningbatchreportPrint`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    batchLabel(id) {
      let selectedBatch = this.batches.filter((data) => data.id == id);

      this.TbatchLabel = selectedBatch[0].batch_number;
    },
    trancheLabelChange(id) {
      let selectedtranche = this.tranches.filter((data) => data.id == id);

      this.TranceLabel = selectedtranche[0].label;
    },
    async getCourseList(id) {
      this.TbatchData = '';
      this.TcourseData = '';
      let selectedInstitute = this.institutes.filter((data) => data.id == id);
      this.TinstituteLabel = selectedInstitute[0].short_name;
      let entity_id = this.TpartnerData;
      let institute_info_id = this.Tinstitute;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      this.load = true;
      await ApiService.get(
        'course/list?entity_id=' +
          entity_id +
          '&tranche=' +
          this.TranceData +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.courses = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async trainingInstitute(id) {
      this.TbatchData = '';
      this.TcourseData = '';
      this.Tinstitute = '';
      let selectedEntity = this.associations.filter((data) => data.id == id);

      this.TpartnerLabel = selectedEntity[0].entity_short_name;

      let entity_id = this.TpartnerData;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get('institute/list?entity_id=' +
          entity_id +
          '&institute_info_id=' +
          institute_info_id)
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getBatchList(id) {
      let selectedCourse = this.courses.filter((data) => data.id == id);

      this.TcourseLabel = `${selectedCourse[0].code}| ${selectedCourse[0].course_name}| ${selectedCourse[0].month}| ${selectedCourse[0].hour}| ${selectedCourse[0].total_course_cost} `;
      let entity_id = this.TpartnerData;
      let institute_info_id = this.Tinstitute;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      this.load = true;
      await ApiService.get(
        'batch/running_batch?entity_id=' +
          entity_id +
          '&tranche_id=' +
          this.TranceData +
          '&course_info_id=' +
          this.TcourseData +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.batches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    resetData() {
      this.TranceLabel = '';
      this.TpartnerLabel = '';
      this.TinstituteLabel = '';
      this.TbatchLabel = '';
      this.TcourseLabel = '';
      this.TranceData = '';
      this.TpartnerData = '';
      this.TcourseData = '';
      this.TbatchData = '';
      this.Tinstitute = '';
      this.tableData = [] as any;
      this.pdfHeader = [] as any;
      this.st = [] as any;
      this.showall = false;
      this.showTableData = false;
      this.componentTableKey += 1;
    },
    async associationList() {
      this.load = true;
      this.TbatchData = '';
      this.TcourseData = '';
      this.Tinstitute = '';
      this.TpartnerData = '';
      let entity_id = this.TpartnerData;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      // console.log(entity_id);
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.associations = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },

    async generateAttendanceReport() {
      if (this.TbatchData) {
        this.load = true;
        await ApiService.get(
          'trainee/attendanceReport?entity=' +
            this.TpartnerData +
            '&tranche=' +
            this.TranceData +
            '&institute=' +
            this.Tinstitute +
            '&course=' +
            this.TcourseData +
            '&batch=' +
            this.TbatchData
        )
          .then((response) => {
            this.headerSelect = true;
            this.selectedTableHeader();
            this.tableData = response.data.data;

            this.componentTableKey += 1;
            this.load = false;
          })
          .then((response) => {
            console.log(response);
            this.load = false;
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please select batch first.',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        });
      }
    },
    headerFilter() {
      this.showCourseNotice = true;
    },
    selectedTableHeader() {
      this.st = Array();
      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
    },
  },
});
